<template>
	<div class="importPersonData">
		<div class="main-filter">
			<el-form inline :model="formInline" class="demo-form-inline">
				<el-form-item label="省：">
					<el-select v-model="formInline.provinceDictionaryItem" placeholder="请选择省" @change="changeEconomize($event, 1)">
						<el-option v-for="item in economizeList" :key="item.id" :label="item.title" :value="item.code"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="市：">
					<el-select v-model="formInline.cityDictionaryItem" placeholder="请选择市" @change="changeEconomize($event, 2)">
						<el-option v-for="item in marketList" :key="item.id" :label="item.title" :value="item.code"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="区县：">
					<el-select v-model="formInline.distinguish" placeholder="请选择" @change="changeEconomize($event, 3)">
						<el-option v-for="item in districtList" :key="item.id" :label="item.title" :value="item.code"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="乡镇：">
					<el-select v-model="formInline.townCode" placeholder="请选择区县" @change="changeEconomize($event, 4)">
						<el-option v-for="item in townList" :key="item.id" :label="item.title" :value="item.code"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="村：">
					<el-select v-model="formInline.villageCode" placeholder="请选择区县" @change="changeEconomize($event, 5)">
						<el-option v-for="item in villageList" :key="item.id" :label="item.title" :value="item.code"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="getData" icon="el-icon-search">搜索</el-button>
				</el-form-item>
			</el-form>
		</div>
		<div class="container">
			<div class="importPersonData-title">
				<div class="importPersonData-title-content">
					<div class="institutionTotal">
						<img src="@/assets/imgs/peoples.png" alt="" />
						<div class="institutionTotal-content">
							<p>重点人群数</p>
							<p>{{ datas.totalNum || 0 }}</p>
						</div>
					</div>
				</div>
			</div>
			<div class="content">
				<el-row :gutter="12">
					<el-col :span="12">
						<div class="chartsClass" ref="importPersonHeight">
							<p>
								<img src="@/assets/imgs/datas.png" alt="" />
								儿童、孕产妇及老人人数统计
							</p>
							<div id="importPerson" :style="{ width: '100%', height: importPersonHeight + 'px' }"></div>
						</div>
						<div class="chartsClass" ref="titleChartsHeight">
							<p>
								<img src="@/assets/imgs/datas.png" alt="" />
								疾病分类及人数统计
							</p>
							<div id="titleCharts" :style="{ width: '100%', height: titleChartsHeight + 'px' }"></div>
						</div>
					</el-col>
					<el-col :span="12">
						<div class="chartsClass" ref="postChartsHeight">
							<p>
								<img src="@/assets/imgs/datas.png" alt="" />
								中医药普及人数统计
							</p>
							<div :style="{ height: postChartsHeight + 'px' }" class="clearfix">
								<div class="float-l chineseMedical">
									<div id="waterCharts1" :style="{ height: 'calc(100% - 100px)' }"></div>
									<div class="legendClass">
										<div class="legendClass-content">
											<p class="legendClass-title">
												<span></span>
												老年人总人数
											</p>
											<p>{{ datas.elderlyNum }}<span>人</span></p>
										</div>
										<div class="legendClass-content">
											<p class="legendClass-title">
												<span></span>
												老人中医药普及人数
											</p>
											<p>{{ datas.tcmelderNum }} <span>人</span></p>
										</div>
									</div>
								</div>
								<div class="float-l chineseMedical mr0">
									<div id="waterCharts2" :style="{ height: 'calc(100% - 100px)' }"></div>
									<div class="legendClass">
										<div class="legendClass-content">
											<p class="legendClass-title">
												<span></span>
												儿童总人数
											</p>
											<p>{{ datas.childrenNum }}<span>人</span></p>
										</div>
										<div class="legendClass-content">
											<p class="legendClass-title">
												<span></span>
												儿童中医药普及人数
											</p>
											<p>{{ datas.tcmchildNum }} <span>人</span></p>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="chartsClass" ref="hospitalChartsHeight">
							<p>
								<img src="@/assets/imgs/datas.png" alt="" />
								老年人健康数据分析
							</p>
							<div id="hospitalCharts" :style="{ width: '100%', height: hospitalChartsHeight + 'px' }"></div>
						</div>
					</el-col>
				</el-row>
			</div>
		</div>
	</div>
</template>

<script>
import 'echarts-liquidfill';
import * as echarts from 'echarts';
export default {
	name: 'importPersonData',
	data() {
		return {
			formInline: {
				provinceDictionaryItem: null,
				cityDictionaryItem: null,
				distinguish: null,
				townCode: null,
				villageCode: null,
			},
			regionCode: null,
			townList: [], //乡镇
			villageList: [], //村
			districtList: [], //区县
			marketList: [], //市
			economizeList: [], //省
			importPersonHeight: 318, // 儿童、孕产妇及老人人数统计
			titleChartsHeight: 318, // 疾病分类及人数统计
			postChartsHeight: 318, // 中医药普及人数统计
			hospitalChartsHeight: 318, // 老年人健康数据分析
			datas: {},
		};
	},
	mounted() {
		this.$nextTick(() => {
			this.importPersonHeight = this.$refs.importPersonHeight.offsetHeight - 90 > 318 ? this.$refs.importPersonHeight.offsetHeight - 90 : 318;
			this.titleChartsHeight = this.$refs.titleChartsHeight.offsetHeight - 90 > 318 ? this.$refs.titleChartsHeight.offsetHeight - 90 : 318;
			this.postChartsHeight = this.$refs.postChartsHeight.offsetHeight - 90 > 318 ? this.$refs.postChartsHeight.offsetHeight - 90 : 318;
			this.hospitalChartsHeight = this.$refs.hospitalChartsHeight.offsetHeight - 90 > 318 ? this.$refs.hospitalChartsHeight.offsetHeight - 90 : 318;
		});
		this.getEconomizeList();
		this.getData();
	},
	methods: {
		getData() {
			let regionCode = this.formInline.villageCode ? this.formInline.villageCode : this.regionCode;
			this.$http
				.get(this.api.getTalentPeople, { params: { regionCode } })
				.then((res) => {
					if (res.data && res.data.success) {
						this.datas = res.data.collection[0] || {};
						let {
							childrenNum,
							pregnantWomenNum,
							elderlyNum,
							diabetesNum,
							hypertensionNum,
							mentalNum,
							tuberculosisNum,
							elderlyHealthNum = 0,
							elderlyDataAnalysis = 0,
							tcmchildRate,
							tcmelderRate,
						} = this.datas;
						// 儿童、孕产妇及老人人数统计
						let data1 = [
							{
								name: '儿童',
								value: childrenNum,
							},
							{
								name: '孕产妇',
								value: pregnantWomenNum,
							},
							{
								name: '老人',
								value: elderlyNum,
							},
						];
						this.getimportPersonData(data1);
						// 疾病分类及人数统计
						let data2 = [
							{
								name: '糖尿病',
								value: diabetesNum,
							},
							{
								name: '高血压',
								value: hypertensionNum,
							},
							{
								name: '精神障碍',
								value: mentalNum,
							},
							{
								name: '结核病',
								value: tuberculosisNum,
							},
						];
						this.getTitleChartsData(data2);
						this.getPostChartsData(tcmchildRate, tcmelderRate);
						// 老年人健康数据分析
						this.getHospitalChartsData(elderlyHealthNum, elderlyDataAnalysis);
					}
				})
				.catch((e) => {});
		},
		// 获取省数据
		getEconomizeList() {
			this.$http
				.get(this.api.getChinaRegions)
				.then((res) => {
					if (res.data && res.data.success) {
						this.economizeList = res.data.collection || [];
						this.economizeList.unshift({
							title: '全部',
							code: null,
						});
					}
				})
				.catch((e) => {});
		},
		// 获取市/区县
		changeEconomize(data, type) {
			this.regionCode = data;
			switch (type) {
				case 1:
					this.formInline.cityDictionaryItem = null;
					this.formInline.distinguish = null;
					this.formInline.townCode = null;
					this.formInline.villageCode = null;
					this.marketList = [];
					this.districtList = [];
					this.townList = [];
					this.villageList = [];
					if (!data || data == '') return;
					this.$http
						.get(this.api.getChinaRegions, { params: { code: data } })
						.then((res) => {
							if (res.data && res.data.success) {
								this.marketList = res.data.collection || [];
								this.marketList.unshift({
									title: '全部',
									code: '',
								});
							}
						})
						.catch((e) => {});
					break;
				case 2:
					this.formInline.distinguish = null;
					this.formInline.townCode = null;
					this.formInline.villageCode = null;
					this.districtList = [];
					this.townList = [];
					this.villageList = [];
					if (!data || data == '') return;
					this.$http
						.get(this.api.getChinaRegions, { params: { code: data } })
						.then((res) => {
							if (res.data && res.data.success) {
								this.districtList = res.data.collection || [];
								this.districtList.unshift({
									title: '全部',
									code: '',
								});
							}
						})
						.catch((e) => {});
					break;
				case 3:
					this.formInline.townCode = null;
					this.formInline.villageCode = null;
					this.townList = [];
					this.villageList = [];
					if (!data || data == '') return;
					this.$http
						.get(this.api.getChinaRegions, { params: { code: data } })
						.then((res) => {
							if (res.data && res.data.success) {
								this.townList = res.data.collection || [];
								this.townList.unshift({
									title: '全部',
									code: '',
								});
							}
						})
						.catch((e) => {});
					break;
				case 4:
					this.formInline.villageCode = null;
					this.villageList = [];
					if (!data || data == '') return;
					this.$http
						.get(this.api.getChinaRegions, { params: { code: data } })
						.then((res) => {
							if (res.data && res.data.success) {
								this.villageList = res.data.collection || [];
								this.villageList.unshift({
									title: '全部',
									code: '',
								});
							}
						})
						.catch((e) => {});
					break;
				default:
					break;
			}
		},
		// 获取儿童、孕产妇及老人人数统计
		getimportPersonData(data) {
			let myChart = echarts.init(document.getElementById('importPerson'));
			let title = '共计(人数)';
			let formatNumber = function (num) {
				let reg = /(?=(\B)(\d{3})+$)/g;
				return num.toString().replace(reg, ',');
			};
			let total = data.reduce((a, b) => {
				return a + b.value * 1;
			}, 0);
			myChart.setOption({
				title: {
					text: '{name|' + title + '}\n{val|' + formatNumber(total) + '}',
					textStyle: {
						color: '#333333',
						rich: {
							name: {
								fontSize: 12,
								fontWeight: 'normal',
								color: '#666666',
								padding: [10, 0],
							},
							val: {
								fontSize: 32,
								fontWeight: 'bold',
								color: '#333333',
							},
						},
					},
					textAlign: 'center',
					x: '23.5%',
					y: 'center',
				},
				tooltip: {
					trigger: 'item',
					show: true,
					formatter: '{b} : {d}人',
					backgroundColor: 'rgba(0,0,0,0.7)', // 背景
					padding: [8, 10], //内边距
					extraCssText: 'box-shadow: 0 0 3px rgba(255, 255, 255, 0.4);', //添加阴影
				},
				color: ['#165DFF', '#04C59F', '#F7BA1E', '#02CADF'],
				legend: {
					backgroundColor: '#F9FAFC',
					padding: [25, 32],
					width: 300,
					type: 'plain',
					icon: 'circle',
					orient: 'vertical',
					left: '45%',
					top: 'center',
					align: 'left',
					itemGap: 15,
					itemWidth: 10, // 设置宽度
					itemHeight: 10, // 设置高度
					symbolKeepAspect: false,
					textStyle: {
						color: '#333',
						rich: {
							name: {
								verticalAlign: 'right',
								align: 'left',
								width: 120,
								fontSize: 12,
							},
							value: {
								align: 'right',
								width: 60,
								fontSize: 12,
							},
						},
					},
					data: data.map((item) => item.name),
					formatter: function (name) {
						if (data && data.length) {
							for (var i = 0; i < data.length; i++) {
								if (name === data[i].name) {
									return '{name| ' + name + '}' + '{value| ' + data[i].value + '(人)}';
								}
							}
						}
					},
				},
				series: [
					{
						name: '数量',
						type: 'pie',
						radius: ['40%', '52%'],
						center: ['24%', '50%'],
						data: data,
						label: {
							normal: {
								show: false,
								position: 'center',
								formatter: '{text|{c}}\n{b}',
								rich: {
									text: {
										align: 'center',
										verticalAlign: 'middle',
										padding: 8,
										fontSize: 30,
									},
									value: {
										align: 'center',
										verticalAlign: 'middle',
										fontSize: 20,
									},
								},
							},
							emphasis: {
								show: false,
							},
						},
						labelLine: {
							normal: {
								show: false,
							},
						},
					},
				],
			});
			window.addEventListener('resize', () => {
				myChart.resize();
			});
		},
		// 获取疾病分类及人数统计
		getTitleChartsData(data) {
			let myChart = echarts.init(document.getElementById('titleCharts'));
			let title = '共计(人数)';
			let formatNumber = function (num) {
				let reg = /(?=(\B)(\d{3})+$)/g;
				return num.toString().replace(reg, ',');
			};
			let total = data.reduce((a, b) => {
				return a + b.value * 1;
			}, 0);
			myChart.setOption({
				title: {
					text: '{name|' + title + '}\n{val|' + formatNumber(total) + '}',
					textAlign: 'center',
					x: '23.5%',
					y: 'center',
					textStyle: {
						color: '#333333',
						rich: {
							name: {
								fontSize: 12,
								fontWeight: 'normal',
								color: '#666666',
								padding: [10, 0],
							},
							val: {
								fontSize: 32,
								fontWeight: 'bold',
								color: '#333333',
							},
						},
					},
				},
				tooltip: {
					trigger: 'item',
					show: true,
					formatter: '{b} : {d}人',
					backgroundColor: 'rgba(0,0,0,0.7)', // 背景
					padding: [8, 10], //内边距
					extraCssText: 'box-shadow: 0 0 3px rgba(255, 255, 255, 0.4);', //添加阴影
				},
				color: ['#165DFF', '#04C59F', '#F7BA1E', '#02CADF', '#D91AD9', '#3491FA', '#FF7D00'],
				legend: {
					backgroundColor: '#F9FAFC',
					padding: [25, 32],
					width: 300,
					type: 'plain',
					icon: 'circle',
					orient: 'vertical',
					left: '45%',
					top: 'center',
					align: 'left',
					itemGap: 15,
					itemWidth: 10, // 设置宽度
					itemHeight: 10, // 设置高度
					symbolKeepAspect: false,
					textStyle: {
						color: '#333',
						rich: {
							name: {
								verticalAlign: 'right',
								align: 'left',
								width: 120,
								fontSize: 12,
							},
							value: {
								align: 'right',
								width: 60,
								fontSize: 12,
							},
						},
					},
					data: data.map((item) => item.name),
					formatter: function (name) {
						if (data && data.length) {
							for (var i = 0; i < data.length; i++) {
								if (name === data[i].name) {
									return '{name| ' + name + '}' + '{value| ' + data[i].value + '(人)}';
								}
							}
						}
					},
				},
				series: [
					{
						name: '数量',
						type: 'pie',
						radius: ['40%', '52%'],
						center: ['24%', '50%'],
						data: data,
						label: {
							normal: {
								show: false,
								position: 'center',
								formatter: '{text|{c}}\n{b}',
								rich: {
									text: {
										align: 'center',
										verticalAlign: 'middle',
										padding: 8,
										fontSize: 30,
									},
									value: {
										align: 'center',
										verticalAlign: 'middle',
										fontSize: 20,
									},
								},
							},
							emphasis: {
								show: false,
							},
						},
						labelLine: {
							normal: {
								show: false,
							},
						},
					},
				],
			});
			window.addEventListener('resize', () => {
				myChart.resize();
			});
		},
		// 获取中医药普及人数统计
		getPostChartsData(tcmchildRate, tcmelderRate) {
			let myChart = echarts.init(document.getElementById('waterCharts1'));
			let value = tcmelderRate.toFixed(2);
			myChart.setOption({
				series: [
					{
						type: 'liquidFill',
						radius: 180,
						center: ['50%', '50%'],
						data: [value, value, value],
						color: ['rgba(22,93,255,1)', 'rgba(22,93,255,.3)', 'rgba(22,93,255,.1)'],
						itemStyle: {
							shadowBlur: 0, // 波浪的阴影范围
						},
						label: {
							formatter: function (param) {
								return '{a|老年人中医药普及比例}' + '\n' + param.value * 100 + '{b|%}';
							},
							position: ['50%', '55%'],
							fontSize: 28,

							rich: {
								a: {
									fontStyle: 'normal',
									fontWeight: 'normal',
									fontSize: 12,
									padding: [10, 0],
								},
								b: {
									fontStyle: 'normal',
									fontWeight: 'normal',
									fontSize: 12,
								},
							},
						},
						outline: {
							show: true,
							borderDistance: 0,
							itemStyle: {
								borderColor: '#165DFF',
								borderWidth: 2,
							},
						},
						backgroundStyle: {
							color: '#fff',
						},
					},
				],
			});
			let myChart2 = echarts.init(document.getElementById('waterCharts2'));
			let value2 = tcmchildRate.toFixed(2);
			myChart2.setOption({
				series: [
					{
						type: 'liquidFill',
						radius: 180,
						center: ['50%', '50%'],
						data: [value2, value2, value2],
						color: ['rgba(22,93,255,1)', 'rgba(22,93,255,.3)', 'rgba(22,93,255,.1)'],
						itemStyle: {
							shadowBlur: 0, // 波浪的阴影范围
						},
						label: {
							formatter: function (param) {
								return '{a|儿童中医药普及比例}' + '\n' + param.value * 100 + '{b|%}';
							},
							position: ['50%', '55%'],
							fontSize: 28,
							rich: {
								a: {
									fontStyle: 'normal',
									fontWeight: 'normal',
									fontSize: 12,
									padding: [10, 0],
								},
								b: {
									fontStyle: 'normal',
									fontWeight: 'normal',
									fontSize: 12,
								},
							},
						},
						outline: {
							show: true,
							borderDistance: 0,
							itemStyle: {
								borderColor: '#165DFF',
								borderWidth: 2,
							},
						},
						backgroundStyle: {
							color: '#fff',
						},
					},
				],
			});
			window.addEventListener('resize', () => {
				myChart.resize();
				myChart2.resize();
			});
		},
		// 获取老年人健康数据分析
		getHospitalChartsData(data, elderlyDataAnalysis) {
			let dataArr = [
				{
					label: '进餐',
					value: elderlyDataAnalysis.mealScore.toFixed(2) * 100,
				},
				{
					label: '活动',
					value: elderlyDataAnalysis.walkingScore.toFixed(2) * 100,
				},
				{
					label: '如厕',
					value: elderlyDataAnalysis.toiletteScore.toFixed(2) * 100,
				},
				{
					label: '穿衣',
					value: elderlyDataAnalysis.dressingScore.toFixed(2) * 100,
				},
				{
					label: '梳洗',
					value: elderlyDataAnalysis.washScore.toFixed(2) * 100,
				},
			];
			let myChart = echarts.init(document.getElementById('hospitalCharts'));
			let value = data;
			myChart.setOption({
				title: {
					text: '{name|老年人健康采集人数' + '} {val|' + value + '} {name|人}',
					textStyle: {
						fontSize: 14,
						fontWeight: 'normal',
						rich: {
							name: {
								fontSize: 14,
								fontWeight: 'normal',
								color: '#333',
							},
							val: {
								fontSize: 20,
								fontWeight: 'bold',
								color: '#333333',
							},
						},
					},
					top: 108,
					left: '60%',
				},
				tooltip: {
					show: true,
					trigger: 'item',
				},
				color: ['#165DFF', '#04C59F'],
				legend: {
					data: ['老年人健康率'],
					backgroundColor: '#F9FAFC',
					padding: [65, 177, 25, 32],
					top: 'center',
					left: '56%',
					icon: 'circle',
					type: 'scroll',
					orient: 'vertical',
					itemGap: 20,
					itemWidth: 16,
					itemHeight: 16,
					textStyle: { color: '#333333', fontSize: 12 },
				},
				radar: {
					name: {
						textStyle: {
							color: '#333',
							fontSize: 14,
						},
					},
					shape: 'polygon',
					center: ['30%', '55%'],
					radius: 100,
					splitNumber: 7, // 雷达图圈数设置
					scale: false,
					indicator: [
						{
							name: '进餐',
							max: 100,
						},
						{
							name: '活动',
							max: 100,
						},
						{
							name: '如厕',
							max: 100,
						},
						{
							name: '穿衣',
							max: 100,
						},
						{
							name: '梳洗',
							max: 100,
						},
					],
					splitArea: {
						show: false,
					},
				},
				series: [
					{
						name: '老年人健康率',
						type: 'radar',
						areaStyle: {
							normal: {
								color: 'rgba(51,112,255,.76)',
							},
						},
						data: [
							{
								value: dataArr.map((item) => item.value),
								itemStyle: {
									normal: {
										color: 'rgba(51,112,255,.76)',
										lineStyle: {
											color: 'rgba(51,112,255,1)',
										},
									},
								},
							},
						],
					},
				],
			});
			window.addEventListener('resize', () => {
				myChart.resize();
			});
		},
	},
};
</script>

<style lang="scss" scoped>
.importPersonData {
	.container {
		margin: 16px;
	}
	.demo-form-inline {
		/deep/ .el-select {
			width: 106px !important;
			.el-input {
				width: 106px !important;
				.el-input__inner {
					width: 106px !important;
				}
			}
		}
		/deep/ .el-input {
			width: 106px;
		}
	}
	&-title {
		padding: 16px;
		background-color: #fff;
		border-radius: 4px;
		margin-bottom: 12px;
		&-content {
			.institutionTotal {
				display: flex;
				align-items: center;
				width: 100%;
				margin-right: 12px;
				background: #f9fafc;
				border-radius: 4px;
				padding: 16px;
				img {
					margin-right: 16px;
				}
				&-content {
					p {
						color: #999;
						font-size: 14px;
						height: 14px;
						line-height: 14px;
						&:last-child {
							font-size: 24px;
							line-height: 24px;
							height: 24px;
							margin-top: 12px;
							color: #333;
						}
					}
				}
			}
		}
	}
	.content {
		.chartsClass {
			background-color: #fff;
			margin-bottom: 12px;
			padding: 20px 16px;
			border-radius: 4px;
			&:last-child {
				margin-bottom: 0;
			}
			p {
				font-size: 16px;
				line-height: 28px;
				font-weight: 500;
				color: #333333;
				margin-bottom: 22px;
				display: flex;
				align-items: center;
				img {
					margin-right: 12px;
				}
			}
			.chineseMedical {
				width: calc(50% - 30px);
				height: 100%;
				margin-right: 60px;
			}
			.legendClass {
				width: 100%;
				max-width: 300px;
				margin: 0 auto;
				padding: 24px 32px;
				background-color: #f9fafc;
				border-radius: 4px;
				&-content {
					display: flex;
					justify-content: space-between;
					align-items: center;
					&:first-child {
						margin-bottom: 20px;
					}
					> p {
						font-size: 16px;
						line-height: 16px;
						height: 16px;
						color: #333;
						margin-bottom: 0 !important;
						span {
							font-size: 12px;
							color: #999;
						}
					}
					.legendClass-title {
						font-size: 12px !important;
						line-height: 12px;
						height: 12px;
						margin-bottom: 0;
						span {
							display: inline-block;
							width: 14px;
							height: 14px;
							border-radius: 50%;
							border: 1px solid #165dff;
							margin-right: 8px;
						}
					}
					&:last-child {
						.legendClass-title {
							span {
								background-color: #165dff;
							}
						}
					}
				}
			}
		}
	}
	.mr0 {
		margin-right: 0 !important;
	}
}
</style>